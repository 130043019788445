import React, { useState, useEffect } from 'react';

import Layout from '../components/layout';
import { SEO } from '../components/seo';

const rgb888_rgb565 = (r, g, b) => {
    let blue = (b >> 3) & 0x1F;
    let green = (g >> 2) & 0x3F;
    let red = (r >> 3) & 0x1F;
    return (red << 11) | (green << 5) | blue;
  };

function RGBConverter() {
  const [r, setR] = useState(0);
  const [g, setG] = useState(0);
  const [b, setB] = useState(0);
  const [webFormat, setWebFormat] = useState('');
  const [result, setResult] = useState(null);
  const [isHelpVisible, setIsHelpVisible] = useState(false);

  const convert = () => {
    const rValue = parseInt(r);
    const gValue = parseInt(g);
    const bValue = parseInt(b);
    const conversionResult = rgb888_rgb565(rValue, gValue, bValue);
    setResult(conversionResult);
  };

  const handleWebFormatChange = (e) => {
    const value = e.target.value;
    setWebFormat(value);
    if (value.length === 6) {
      const rValue = parseInt(value.substring(0, 2), 16);
      const gValue = parseInt(value.substring(2, 4), 16);
      const bValue = parseInt(value.substring(4, 6), 16);
      setR(rValue);
      setG(gValue);
      setB(bValue);
    }
  };


  useEffect(() => {
    // Verifica se estamos no ambiente de navegador
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      // Cria o elemento de script
      const script = document.createElement('script');

      // Define o conteúdo do script
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-SE4NY559V3');
      `;

      // Insere o script no início do body
      document.body.insertBefore(script, document.body.firstChild);

      // Limpeza ao desmontar o componente
      return () => {
        document.body.removeChild(script);
      };
    }

  }, []);  // O array vazio significa que este useEffect só é executado uma vez após o primeiro render



  return (
    <>
    <Layout />

    <div className='p-sm absolute right-10 top-32 w-128 h-auto rounded-xl bg-gradient-to-b from-white to-transparent bg-opacity-70'
      onMouseEnter={() => setIsHelpVisible(true)}
      onMouseLeave={() => setIsHelpVisible(false)}
    >
      <h2 className='text-orange-700'>{isHelpVisible ? 'RGB24 para RGB16' : 'Está com dúvidas?'}</h2>
      <p 
        className={`text-gray-900 text-justify transition-all ease-in duration-300 ${isHelpVisible ? 'h-144 opacity-100' : 'h-0 opacity-0'}`}
      >
        Insira os valores decimais de vermelho, verde e azul, entre 0 e 255 e clique em "Convert".
        Você pode optar por entrar com o valor em hexadecimal e ele será convertido para decimal automaticamente.<br />
        Depois é só clicar em "Convert" para obter o valor RGB16. Fácil!
      </p>
    </div>

    <div className=' relative top-96 flex flex-col '>
            <div className='overflow-hidden font-sans font-extralight ring-1 ring-orange-500 rounded-xl left-1/2  bg-black bg-opacity-60'>

                <div className='p-sm ml-4 relative'>
                  <div className='justify-center items-center flex flex-row'>
                    RED<input className='m-4  text-red-500 rounded-lg bg-black bg-opacity-50 ring-1 ring-orange-500 ring-opacity-70 w-24' type="number" value={r} onChange={e => setR(e.target.value)} placeholder="R" />
                    GREEN<input className='m-4 text-green-500 rounded-lg bg-black bg-opacity-50 ring-1 ring-orange-500 ring-opacity-70 w-24' type="number" value={g} onChange={e => setG(e.target.value)} placeholder="G" />
                    BLUE<input className='m-4 text-blue-500 rounded-lg bg-black bg-opacity-50 ring-1 ring-orange-500 ring-opacity-70 w-24' type="number" value={b} onChange={e => setB(e.target.value)} placeholder="B" />
                  </div>
           
                <br />
                <div className='flex flex-row relative left-2  items-center'>Or:&nbsp;&nbsp; <h2> # </h2>
                <input className='m-4 ml-0 text-orange-500 rounded-lg bg-black bg-opacity-60 ring-1 ring-orange-500 ring-opacity-70 w-24' type="text" value={webFormat} onChange={handleWebFormatChange} placeholder="RRGGBB" maxLength="6" />
                </div>
                <button className='absolute right-0 bottom-0 m-4 text-orange-100 rounded-lg bg-black bg-opacity-60 ring-1 ring-orange-500 ring-opacity-70 w-24' onClick={convert}>Convert</button>
                <div className='text-orange-100 absolute w-40 left-52 top-28 mb-2'><br/>Result:&nbsp;&nbsp; {result !== null ? `0x${result.toString(16).toUpperCase()}` : 'N/A'}</div>

            </div>
        </div>
    </div>
    </>
  );
}

export default RGBConverter;

<SEO
    title={"Conversor RGB"}
    description={"Conversor RGB24 para RGB16"}
    pathname={"rgb-converter/"}
    children={""}
    pageType={"Article"}
    publishedAt={"2023-09-01"}
    thumbnail={"/manualdomaker-toolbox.png"}
  />