import React from 'react'
import { useSiteMetadata } from "../hooks/use-site-metadata"
import removeMarkdown from 'remove-markdown';

export const SEO = ({ title, description, pathname, readingTime, thumbnail, publishedAt, pageType, children }) => {
  const { title: defaultTitle, description: defaultDescription, image, siteUrl, twitterUsername } = useSiteMetadata()

  let plainTextDescription;
  let fullURLimg = siteUrl;
  let fullURL    = siteUrl;

  if (description) {
    plainTextDescription = removeMarkdown(description);
  } 
  else{
    plainTextDescription = defaultDescription;
  }


  if (thumbnail && thumbnail !== "") {
    fullURLimg = fullURLimg  + thumbnail;
  }
  else {
    fullURLimg = fullURLimg  + image;
  }

  // if (pathname) {
  //   const cleanedPathname = pathname.replace(/^\/|\/$/g, "");
  //   fullURL = `${fullURL}/${cleanedPathname}/`;
  //   //fullURL = fullURL + "/" + pathname + "/";
  // }
  if (pathname) {
    const cleanedPathname = pathname.replace(/^\/|\/$/g, "");
    fullURL = `${fullURL}/${cleanedPathname}/`;
  } else {
    // Se estamos na home (pathname não fornecido), garantimos a barra ao final
    fullURL = `${fullURL}/`;
  }

  const seo = {
    title: title || defaultTitle,
    description: plainTextDescription,
    image: fullURLimg,
    url: fullURL,
    pubDate: publishedAt,
    twitterUsername,
    pageType
  }
  const canonicalURL = fullURL.endsWith('/') ? fullURL : `${fullURL}/`;

  // pageType pode ser "Article" para artigo e "CollectionPage" para os cards
  let currentDate = new Date().toISOString().split("T")[0];
  return (
    <>
        <meta charSet="UTF-8" />
        <meta name="description" content={seo.description}></meta>
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={`${seo.title} • Manual do Maker`} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:url" content={seo.url} />
        <meta property="og:site_name" content="Manual do Maker" />
        <meta property="og:image" content={seo.image} />
        <meta property="og:image:alt" content={seo.title} />
        <meta property="og:image:type" content="image/webp" />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />

        <title>{seo.title}</title>
        <meta name="twitter:image" content={seo.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:url" content={seo.url} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:creator" content={seo.twitterUsername} />
        <meta name="twitter:label1" content="Escrito por" />
        <meta name="twitter:data1" content="Djames Suhanko" />
        <meta name="twitter:label2" content="Est. tempo de leitura" />
        <meta name="twitter:data2" content={readingTime ? readingTime + " min." : "~3 min."} />

        <link rel="alternate" type="application/rss+xml" title="RSS Feed for https://www.manualdomaker.com/" href="/rss.xml" />

        {/** ATTENTION: REMOVER O ROBOTS ABAIXO E DESCOMENTAR O ROBOTS QUE INDEXA, QUANDO FOR PRODUCAO!!!! perguntar sobre o arquivo robots.txt */}
        {/* <meta name="robots" content="noindex" /> */}
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />

        <link rel="canonical" href={canonicalURL} />

        <meta name="google-site-verification" content="G7xKgOCFjKt0i2pObrcfAO60im_6N-Jwg7ZxeSxm8jM" />
        

        <meta name="yandex-verification" content="c1650be0ddf0727a" />

        <meta name="msvalidate.01" content="A2A708ECCAE2283B750F56428B33D1D9" />

        {pageType === "Article" && publishedAt && (
          <>
          <meta property="article:published_time" content={seo.pubDate}></meta>
          <meta property="article:modified_time" content={seo.pubDate}></meta>
          <script type="application/ld+json">
            {JSON.stringify({
               "@context": "http://schema.org",
               "@type": seo.pageType,
               "mainEntityOfPage": {
                 "@type": "WebPage",
                 "@id": seo.url
               },
               "headline": seo.title,
               "description": seo.description,
               "image": seo.image,
               "author": {
                 "@type": "Person",
                 "name": "Djames Suhanko" 
               },
               "datePublished": seo.pubDate, 
               "dateModified": seo.pubDate, 
               "publisher": {
                 "@type": "Organization",
                 "name": "Manual do Maker",
                 "logo": {
                   "@type": "ImageObject",
                   "url": "/manualdomaker-card.png"
                 }
               }
              })}
            </script> 
          </>
        )}

        {pageType === "CollectionPage" && (
          <>
          <meta property="article:modified_time" content={currentDate}></meta>
          <script type="application/ld+json">
            {JSON.stringify({
               "@context": "http://schema.org",
               "@type": seo.pageType,
               "mainEntityOfPage": {
                 "@type": "WebPage",
                 "@id": seo.url
               },
               "headline": seo.title,
               "description": seo.description,
               "image": seo.image,
               "author": {
                 "@type": "Person",
                 "name": "Djames Suhanko" // Pode ser adaptado para aceitar outros autores, se necessário
               },
               "dateModified": currentDate, // Adicione a data de modificação da postagem, se houver
               "publisher": {
                 "@type": "Organization",
                 "name": "Manual do Maker",
                 "logo": {
                   "@type": "ImageObject",
                   "url": "/manualdomaker-card.png" // Substitua pela URL do logo do seu site
                 }
               }
              })}
            </script> 
          </>
        )}  
        {/* Ambas as imagens são necessárias (png e svg, para ponts diferentes) */}
        <link rel="icon" href="/manualdomaker.png" />


        
        {children}
    </>
  )
}